@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
  font-size: 100%;
}

.timer-container {
  background-image: url('./background.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  width: 100vw;
  height: 100vh;
}

.timer {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 7em;
  padding-top: 2em;
  width: 100%;
  height: 100%;
}

.timer div{
  border: 1px solid #fff;
  border-radius: 3px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-top: 3em;
  padding: 3em;
  text-align: center;
}

section p:first-child, .timer div span {
  font-size: 3em;
}

@media screen and (max-width: 480px) {
  .timer div:last-child {
    margin-left: 2em;
    margin-right: 2em;
    padding: 3em 1em;
  }
}